import React, { useEffect } from 'react';
import './TypoLectureSection.css';
import { Link, useNavigate } from 'react-router-dom';
// Dummy lecture data (you can replace with your actual lecture data)
const lectures = [
    {
        id: 1,
        title: 'Learn Python Basic',
        image: '/assets/Lecture_img/python_basic.png',
    },
    {
        id: 2,
        title: 'Learn Python Advanced',
        image: '/assets/Lecture_img/python_advanced.png',
    },
    {
        id: 3,
        title: 'Learn Python Class',
        image: '/assets/Lecture_img/python_class.png',
    },
    {
        id: 4,
        title: 'Learn Pygame',
        image: '/assets/Lecture_img/python_pygame.png',
    },
    {
        id: 5,
        title: 'Learn C Basic',
        image: '/assets/Lecture_img/c_basic.png',
    },
    {
        id: 6,
        title: 'Learn C Advanced',
        image: '/assets/Lecture_img/c_advanced.png',
    },
    {
        id: 7,
        title: 'Learn Alogorithm Basic',
        image: '/assets/Lecture_img/algorithm_basic.png',
    },
    {
        id: 8,
        title: 'Learn Alogorithm Advanced',
        image: '/assets/Lecture_img/algorithm_advanced.png',
    },
    {
        id: 9,
        title: 'Learn Alogorithm Master',
        image: '/assets/Lecture_img/algorithm_master.png',
    },
];

function TypoLectureSection() {
    useEffect(() => {
        // Clear the saved project when returning to the main page
        localStorage.removeItem('currentProject');
    }, []);

    return (
        <div className='TypoLectureSection-con'>
            <div className='TypoLectureMain-body'>
                <div className='TypoLectureGrid'>
                    {lectures.map((lecture) => (
                        <Link to="/compiler" 
                        key={lecture.id}
                            className='TypoLectureGrid-box'
                            style={{
                                backgroundImage: `url(${lecture.image})`,
                                
                            }}
                            >
                        
                            <div className='TypoLectureGrid-box-title'>
                                {lecture.title}
                            </div>
                   
                        
                        </Link>

                    ))}
                </div>
            </div>
        </div>
    );
}

export default TypoLectureSection;
