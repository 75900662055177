
import './App.css';
import { RecoilRoot } from 'recoil';
import Router from './Components/Router.js';


function App() {
  return (
    <RecoilRoot>
      <div className="App">
            <Router />
      </div>
    </RecoilRoot>
  );
}

export default App;
