import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './TypoNavBar.css';
import logo_img from '../../img_set/logo/typo_logo.png';
import { IoPersonCircle } from 'react-icons/io5';
import { BiGhost } from "react-icons/bi";
import { LuFactory } from "react-icons/lu";
import { MdOutlineClass } from "react-icons/md";
import { MdOutlinePeopleAlt, MdExplore } from "react-icons/md";
import { IoSchool } from "react-icons/io5";
import useStore from '../GlobalStore'; // Adjust the import path as necessary
import { useLanguage } from '../../LanguageContext';
import texts from '../../texts';
import Swal from 'sweetalert2';

function TypoNavBar() {
    const { isLoggedIn, userName, logout } = useStore();
    const { language, setLanguage } = useLanguage("ko");

    const navigate = useNavigate();
    const text = texts[language];

    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
    };

    const handleLogout = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you really want to logout?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, logout!'
        }).then((result) => {
            if (result.isConfirmed) {
                logout();
                Swal.fire('Logged out!', 'You have been logged out successfully.', 'success');
                navigate('/'); // Redirect to home after logout
            }
        });
    };

    const handleLoginClick = () => {
        if (isLoggedIn) {
            handleLogout();
        } else {
            navigate('/login');
        }
    };

    return (
        <div className='Typo-nav-con'>
            <div className='Typo-nav-box-l'>
                <Link to="/" className='Typo-nav-item-box'>
                    <img
                        src={logo_img}
                        width="109"
                        height="50"
                        alt="Logo"
                    />
                </Link>
                <select value={language} onChange={handleLanguageChange} className="language-selector">
                    <option value="en">English</option>
                    <option value="ko">한국어</option>
                </select>
            </div>
            <div className='Typo-nav-box-m'>
                <Link to="/compiler" className='Typo-nav-item-box'>
                    <BiGhost size={20} />
                    <span>{text.typo_nav.make}</span>
                </Link>

                <Link to="/typoexplore" className='Typo-nav-item-box'>
                    <MdExplore size={20} />
                    <span>{text.typo_nav.explore}</span>
                </Link>
            </div>
            <div className='Typo-nav-box-r'>
                <div className='Typo-nav-item-box' onClick={handleLoginClick}>
                    <IoPersonCircle size={30} />
                    <span>{isLoggedIn ? `${userName} (Logout)` : text.typo_nav.login}</span>
                </div>
            </div>
        </div>
    );
}

export default TypoNavBar;
