import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Swal from 'sweetalert2';
import useStore from '../GlobalStore'; // Adjust the import path as necessary
import TypoNavBar from './TypoNavBar';
import TypoMainFooter from './TypoMainFooter';
import "./TypoLoginPage.css";
import { useNavigate, useLocation } from 'react-router-dom';

function TypoLoginPage() {
    const ENDPOINT =  process.env.REACT_APP_CloudTypeServer||"http://localhost:4000";

    // Zustand state
    const { isLoggedIn, setLoginState, setUserType, setUserName, setUserStringId, setUserId } = useStore();

    // Local state for form inputs
    const [isRegister, setIsRegister] = useState(false); // To toggle between login and register forms
    const [fullName, setFullName] = useState('');  // User's full name
    const [userEmail, setUserEmail] = useState(''); // User's email (required for teachers)
    const [phoneNumber, setPhoneNumber] = useState('');  // User's phone number (required for teachers)
    const [userId, setUserIdState] = useState('');  // Add this state to track user ID
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [role, setRole] = useState('student');  // User's role (student or teacher)
    const navigate = useNavigate();
    const location = useLocation();
    
    // Retrieve project from state if present
    const currentProject = location.state?.project || null;

    // Function to handle user login
    const handleLogin = () => {
        Axios.post(`${ENDPOINT}/api/login`, { id: userId, password }) // Use userId state here
            .then(response => {
                Swal.fire('Success', 'Logged in successfully!', 'success');
                setLoginState(true);
                setUserName(response.data.name); // Set user's name immediately
                setUserStringId(response.data.user_string_id); // Set user's ID
                setUserType(response.data.type); // Set user's role (type)
                setUserId(response.data.user_id);
                // Navigate back to the previous page or to the compiler with the current project
                const redirectTo = location.state?.from?.pathname || '/';
                navigate(redirectTo, { state: { project: currentProject } });
            })
            .catch(error => {
                Swal.fire('Error', 'Invalid ID or password', 'error');
            });
    };

    // Function to handle user registration
    const handleRegister = () => {
        if (password !== confirmPassword) {
            Swal.fire('Error', 'Passwords do not match', 'error');
            return;
        }

        if (role === 'teacher' && (!userEmail || !phoneNumber)) {
            Swal.fire('Error', 'Email and phone number are required for teachers', 'error');
            return;
        }

        Axios.post(`${ENDPOINT}/api/register`, { 
            name: fullName,
            id: userId,  // Use userId state here
            password, 
            email: role === 'teacher' ? userEmail : undefined,
            phone: role === 'teacher' ? phoneNumber : undefined,
            type: role === 'teacher' ? 1 : 0 
        })
        .then(response => {
            Swal.fire('Success', 'Registered successfully!', 'success');
            setIsRegister(false); // Switch to login form after successful registration
        })
        .catch(error => {
            Swal.fire('Error', 'Failed to register', 'error');
        });
    };

    // Function to handle form submission on Enter key press
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (isRegister) {
                handleRegister();
            } else {
                handleLogin();
            }
        }
    };

    // Attach keypress event listener
    useEffect(() => {
        window.addEventListener('keypress', handleKeyPress);
        return () => {
            window.removeEventListener('keypress', handleKeyPress);
        };
    }, [handleKeyPress, isRegister]);

    return (
        <div className='Typo-login-con'>
           <div className='typo-explore-nav-head'>
                <TypoNavBar />
            </div>
            <div className='Typo-login-head'>
                <h1>{isRegister ? '회원가입' : '로그인'}</h1>
            </div>
            <div className='Typo-login-body'>
                {isRegister && (
                    <>
                        <div className='Typo-login-box'>
                            <span>회원 구분</span>
                            <select value={role} onChange={(e) => setRole(e.target.value)}>
                                <option value="student">학생</option>
                                <option value="teacher">선생님</option>
                            </select>
                        </div>
                        <div className='Typo-login-box'>
                            <span>사용자 이름 입력</span>
                            <input value={fullName} onChange={(e) => setFullName(e.target.value)} />
                        </div>
                        <div className='Typo-login-box'>
                            <span>사용자 ID 입력</span>
                            <input value={userId} onChange={(e) => setUserIdState(e.target.value)} />  {/* Use setUserIdState here */}
                        </div>
                        {role === 'teacher' && (
                            <>
                                <div className='Typo-login-box'>
                                    <span>이메일 입력</span>
                                    <input type="email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} />
                                </div>
                                <div className='Typo-login-box'>
                                    <span>전화번호 입력</span>
                                    <input type="tel" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                </div>
                            </>
                        )}
                    </>
                )}
                {!isRegister && (
                    <div className='Typo-login-box'>
                        <span>사용자 ID 입력</span>
                        <input value={userId} onChange={(e) => setUserIdState(e.target.value)} />  {/* Use setUserIdState here */}
                    </div>
                )}
                <div className='Typo-login-box'>
                    <span>비밀번호 입력</span>
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                {isRegister && (
                    <div className='Typo-login-box'>
                        <span>비밀번호 확인</span>
                        <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                    </div>
                )}
                <button
                    className="Typo-custom-login-button"
                    onClick={isRegister ? handleRegister : handleLogin}
                >
                    {isRegister ? '회원가입' : '로그인'}
                </button>
            </div>
            <div className='Typo-login-footer'>
                <button
                    className="Typo-custom-login-button"
                    onClick={() => setIsRegister(!isRegister)}
                >
                    {isRegister ? '로그인 화면으로 돌아가기' : '회원가입'}
                </button>
            </div>
            <div className='Typo-login-footer-foot'>
                <TypoMainFooter />
            </div>
        </div>
    );
}

export default TypoLoginPage;
