import React, { useEffect } from 'react';
import TypoNavBar from '../TypoNavBar';
import TypoLectureSection from './TypoLectureSection';
import "./TypoLectureMain.css";

function TypoLectureMain() {

    useEffect(() => {
        // Clear the saved project when returning to the main page
        localStorage.removeItem('currentProject');
    }, []);

    return (
        <div className='TypoLectureMain-con'>
            <div className='TypoLectureMain-head'>
                <TypoNavBar />
            </div>
            <div className='TypoLectureMain-bodycon'>
            <div className='TypoLectureMain-body'>
                <div className='TypoLectureMain-body-box'>
                    <TypoLectureSection/>
                </div>
                    
            </div>
            </div>
        </div>
    );
}

export default TypoLectureMain;
