import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const DataChartApp = ({ parsedData }) => {
  if (!parsedData) {
    return <div>그래프를 생성하려면 실행 버튼을 눌러주세요.</div>;
  }

  return (
    <div style={{ marginTop: '20px', textAlign: 'left', width: '100%', height: '1000px' }}> {/* 그래프가 길어지도록 설정 */}
      <h2>{parsedData.title} Bar Chart</h2>
      <ResponsiveContainer width="100%" height={800}> {/* 높이를 크게 설정 */}
        <BarChart data={parsedData.data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="grade1" fill="#8884d8" />
          <Bar dataKey="grade2" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DataChartApp;
