import React, { useState } from 'react';
import Editor from '@monaco-editor/react';
import './DemoShowPage.css';
import pre_game_img from '../../img_set/cute-robot-back.gif';
import DataChartApp from './DataChartApp';
import HandGestureApp from './HandGestureApp';
import Modal from 'react-modal';
import { IoMdEye } from "react-icons/io"; // Vision 아이콘
import { GiGalaxy } from "react-icons/gi"; // Space 아이콘
import { BsBarChartFill } from "react-icons/bs"; // Data 아이콘
import { VscRunAll } from "react-icons/vsc";
import { TiSpanner } from "react-icons/ti";

const DemoShowPage = () => {
    const [code, setCode] = useState('// 여기에 코드를 작성하세요');
    const [activePage, setActivePage] = useState(null);
    const [runData, setRunData] = useState(null); // 파싱된 데이터를 저장
    const [sections, setSections] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [resultVisible, setResultVisible] = useState(false);

    const handleSetActivePage = (page) => {
        setActivePage(page);
        setCode('# 여기에 코드를 작성하세요');
        setResultVisible(false); // 페이지 변경 시 result 숨김
    };

    const handleRun = () => {
        setResultVisible(true);
        if (activePage === 'data') {
            const parsePythonCode = (code) => {
                try {
                    let modifiedCode = code
                        .replace(/'/g, '"')
                        .replace(/True/g, 'true')
                        .replace(/False/g, 'false');

                    let titleMatch = modifiedCode.match(/graph\.title\s*=\s*"([^"]+)"/);
                    let title = titleMatch ? titleMatch[1] : "No Title";

                    let dataString = modifiedCode.match(/data\s*=\s*(\[.*\])/s)?.[1];
                    let data = dataString ? JSON.parse(dataString) : [];

                    return { title, data };
                } catch (error) {
                    console.error("Error parsing the code:", error);
                    return { title: "Error", data: [] };
                }
            };

            // 파싱 결과를 저장
            const result = parsePythonCode(code);
            setRunData(result);
        } else if (activePage === 'space') {

        } else if (activePage === 'vision') {

        }
    };

    const handleAddSection = (section) => {
        if (section && !sections.includes(section)) {
            setSections([...sections, section]); // 섹션 추가
        }
        setIsModalOpen(false); // 모달 닫기
    };

    // 선택된 페이지에 맞는 페이지 내용을 렌더링
    const renderActivePage = () => {
        switch (activePage) {
            case 'data':
                return <DataChartApp parsedData={runData} />; // 파싱된 데이터 전달
            case 'vision':
                return <HandGestureApp />;
            case 'space':
                return (
                    <iframe
                        src="/tes2t.html"
                        style={{ width: '100%', height: '100%', border: 'none' }}
                        title="HTML Content"
                    ></iframe>
                );
            default:
                return <div>페이지를 선택하세요</div>;
        }
    };

    // Left section에 맞는 설명 렌더링
    const renderLeftSectionContent = () => {
        switch (activePage) {
            case 'data':
                return (
                    <>
                        <h3>📊 평균에 대한 수학적 설명</h3>
                        <p>평균은 데이터 값들을 더한 뒤, 데이터의 개수로 나눈 값입니다. 예를 들어, <br />
                            90, 80, 70 점을 가진 세 과목의 평균은 (90+80+70) ÷ 3 = 80 입니다.<br />
                            점수를 딕셔너리 형태로 입력한 후 그래프를 그려봅시다!
                        </p>
                        <h3>📊 그래프 그리기</h3>
                        <pre style={{
                            backgroundColor: '#000',
                            color: '#fff',
                            padding: '15px',
                            borderRadius: '15px',
                            fontSize: '12px',
                            lineHeight: '1.5',
                            overflowX: 'auto'
                        }}>
                            <code>
                                graph.title = "Grade"<br />
                                data = [<br />
                                {"{"}"name" : "Math", "grade1": 90, "grade2": 80{"}"},<br />
                                {"{"}"name": "English", "grade1": 85, "grade2": 88{"}"},<br />
                                {"{"}"name": "Science", "grade1": 78, "grade2": 84{"}"}<br />
                                ]
                            </code>
                        </pre>
                    </>
                );
            case 'space':
                return (
                    <>
                        <h3>🌌3d 은하 모델링</h3>
                        <p>은하는 수많은 별들, 가스, 먼지, 암흑물질 등으로 구성된 커다란 집합체입니다. 🌟<br />
                            우리 은하(Milky Way)는 약 1천억 개의 별을 포함하며, 나선형 구조를 가지고 있습니다.<br />
                            3d cube들로 이루어진 은하를 생성해 봅시다!</p>
                        <h3>🌌 Galaxy 생성 예시 코드</h3>
                        <pre style={{
                            backgroundColor: '#000',
                            color: '#fff',
                            padding: '15px',
                            borderRadius: '15px',
                            fontSize: '12px',
                            lineHeight: '1.5',
                            overflowX: 'auto'
                        }}>
                            <code>
                                create_galaxy("#000000", [0x87CEFA, 0xFFFFFF, 0xFF4500], 5000, 4)<br />
                                <br />
                                async def main():<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;while True:<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;time = performance.now() * 0.0005<br />
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;# Rotate the galaxy slowly around the center<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;galaxyGroup.rotation.y += 0.002  # Slower, majestic rotation speed<br />
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;# Tilt the galaxy based on mouse movement for interaction<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;galaxyGroup.rotation.x -= ((-mouse.y * 2) + galaxyGroup.rotation.x) * 0.01<br />
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;# Render the scene<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;renderer.render(scene, camera)<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;await asyncio.sleep(0.02)
                            </code>
                        </pre>
                    </>
                );
            case 'vision':
                return (
                    <>
                        <h3>👁️ Vision 인공지능 작동 원리</h3>
                        <p>Vision AI는 이미지를 분석하여 객체를 인식하는 기술입니다. 📸</p>
                        <h3>TensorFlow 손 인식 예시 코드</h3>
                        <pre style={{
                            backgroundColor: '#000',
                            color: '#fff',
                            padding: '15px',
                            borderRadius: '15px',
                            fontSize: '12px',
                            lineHeight: '1.5',
                            overflowX: 'auto'
                        }}>
                            <code>
                                import cv2<br />
                                import mediapipe as mp<br />
                                <br />
                                mp_hands = mp.solutions.hands<br />
                                mp_drawing = mp.solutions.drawing_utils<br />
                                <br />
                                cap = cv2.VideoCapture(0)<br />
                                with mp_hands.Hands(max_num_hands=2, min_detection_confidence=0.7, min_tracking_confidence=0.5) as hands:<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;while cap.isOpened():<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;success, image = cap.read()<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;image = cv2.cvtColor(image, cv2.COLOR_BGR2RGB)<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;result = hands.process(image)<br />
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if result.multi_hand_landmarks:<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;for hand_landmarks in result.multi_hand_landmarks:<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;mp_drawing.draw_landmarks(image, hand_landmarks, mp_hands.HAND_CONNECTIONS)<br />
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;image = cv2.cvtColor(image, cv2.COLOR_RGB2BGR)<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;cv2.imshow('Hand Tracking', image)<br />
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if cv2.waitKey(5) & 0xFF == 27:<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;break<br />
                                <br />
                                cap.release()<br />
                                cv2.destroyAllWindows()
                            </code>
                        </pre>
                    </>
                );
            default:
                return <p>섹션을 선택하세요</p>;
        }
    };


    return (
        <div className="DemoShowPage-container">
            <div className="DemoShowPage-header">
                <h2>CS 팩토리</h2>
            </div>
            <div className="DemoShowPage-body">
                {/* Left Sector에 내용 렌더링 */}
                <div className="DemoShowPage-left">
                    {renderLeftSectionContent()}
                </div>
                <div className="DemoShowPage-center">
                    <div className="DemoShowPage-editor-run">
                        <button className="DemoShowPage-run-button" onClick={handleRun}><VscRunAll size={25} /></button>
                    </div>
                    <div className="DemoShowPage-editor">
                        <Editor
                            height="90%"
                            width="100%"
                            defaultLanguage="python"
                            value={code}
                            onChange={(value) => setCode(value)}
                            theme="vs-dark"
                        />
                    </div>
                </div>
                <div className="DemoShowPage-right">
                    <div className="DemoShowPage-result">
                        {resultVisible ? renderActivePage() : <div className='DemoShowPage-centered-image-con'>
                            <img src={pre_game_img} alt="Cute Robot" className="DemoShowPage-centered-image DemoShowPage-circle-image" />
                        </div>}
                    </div>
                    <div className="DemoShowPage-buttons">
                        {sections.map((section) => (
                            <button key={section} className="DemoShowPage-button" onClick={() => handleSetActivePage(section)}>
                                {section.charAt(0).toUpperCase() + section.slice(1)}
                            </button>
                        ))}
                        <button className="DemoShowPage-add-section-button" onClick={() => setIsModalOpen(true)}>
                            섹션 추가
                        </button>
                    </div>
                </div>
            </div>

            {/* 모달 창 */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                className="DemoShowPage-modal"
                overlayClassName="DemoShowPage-modal-overlay"
            >
                <h2>섹션 추가</h2>
                <div className="DemoShowPage-modal-options">
                    <div className="DemoShowPage-modal-option" onClick={() => handleAddSection('data')}>
                        <BsBarChartFill className="DemoShowPage-modal-icon" /> Data
                    </div>
                    <div className="DemoShowPage-modal-option" onClick={() => handleAddSection('space')}>
                        <GiGalaxy className="DemoShowPage-modal-icon" /> Space
                    </div>
                    <div className="DemoShowPage-modal-option" onClick={() => handleAddSection('vision')}>
                        <IoMdEye className="DemoShowPage-modal-icon" /> Vision
                    </div>

                </div>
                <button className="DemoShowPage-modal-close-button" onClick={() => setIsModalOpen(false)}>취소</button>
            </Modal>
        </div>
    );
};

export default DemoShowPage;
