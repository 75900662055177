import './CwsStageLecture.css';
import React, { useState } from 'react';

function CwsStageLecture({ lectures }) {
    const [currentLectureIndex, setCurrentLectureIndex] = useState(0);  // Track current lecture
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);  // Track sidebar state

    const currentLecture = lectures[currentLectureIndex];  // Get current lecture data

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);  // Toggle sidebar open/close
    };

    const handleLectureClick = (index) => {
        setCurrentLectureIndex(index);  // Change lecture when sidebar item is clicked
        setIsSidebarOpen(false);  // Close sidebar after selecting a lecture
    };

    return (
        <div className="CwsStageLecture-con">
            {/* Sidebar with Lecture List */}
            <div className={`CwsStageLecture-sidebar ${isSidebarOpen ? 'open' : ''}`}>
                {/* Move the Toggle Button inside the Sidebar when it's open */}
                <button className={`CwsStageLecture-tabButton ${isSidebarOpen ? 'inSidebar' : ''}`} onClick={toggleSidebar}>
                    {isSidebarOpen ? '📖 Close Lectures' : '📖'}
                </button>

                <ul>
                    {lectures.map((lecture, index) => (
                        <li
                            key={index}
                            className={`CwsStageLecture-listItem ${index === currentLectureIndex ? 'active' : ''}`}
                            onClick={() => handleLectureClick(index)}
                        >
                            {lecture.title}
                        </li>
                    ))}
                </ul>
            </div>

            {/* Current Lecture Section */}
            <div className={`CwsStageLecture-content ${isSidebarOpen ? 'contentShift' : ''}`}>
                {/* Title Section */}
                <div className="CwsStageLecture-title">
                    🌟 {currentLecture.title} 🌟
                </div>

                {/* YouTube Video Section */}
                <iframe
                    className="CwsStageLecture-video"
                    src={`https://www.youtube.com/embed/${currentLecture.videoUrl}`}
                    title={currentLecture.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>

                {/* Description Section */}
                <div className="CwsStageLecture-description">
                    <h2>🛠️ 미션: {currentLecture.description.missionTitle}</h2>
                    <p>📜 <strong>미션 설명:</strong> {currentLecture.description.missionExplanation}</p>
                    <h3>✏️ 미션 조건:</h3>
                    <ul style={{ listStyleType: 'none', paddingLeft: 20 }}>
                        {currentLecture.description.missionCondition.map((condition, index) => (
                            <li key={index}>📌 {condition}</li>
                        ))}
                    </ul>
                    <h3>🔧 사용 함수:</h3>
                    <ul style={{ listStyleType: 'none', paddingLeft: 20 }}>
                        {currentLecture.description.usedFunctions.map((func, index) => (
                            <li key={index}>⚙️ {func}</li>
                        ))}
                    </ul>

                </div>

            </div>
        </div>
    );
}

export default CwsStageLecture;
