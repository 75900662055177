import React, { useState } from 'react';
import './CwsLeftSec.css';
import { FaBookOpen } from "react-icons/fa";
import { RiQuillPenLine } from "react-icons/ri";
import CwsCommandEx from './CwsCommandEx';
import CwsStageLecture from './CwsStageLecture';
import CwsNotionView from './CwsNotionView';
import { useLanguage } from '../../LanguageContext';
import texts from '../../texts';

// Sample lecture data
const lectures = [
    {
        title: "마법사의 궁극기",
        

        videoUrl: "YA6reYjkVv4",  // YouTube Video ID
        description: {
            missionTitle: "시간 마법을 시전해보자!",
            missionExplanation: "Time 이팩트를 추가하고 위치를 이동시킵니다! 공격모션으로 마법을 시전하세요!",
            usedFunctions: ["motion", "set_xy"],
            missionCondition : ["변수를 만들고 값을 저장!","마법사 모션 설정하기 (attack)","배경 설정하기","time 이팩트 3개 추가하기(위치 저장 이동!)"]
        }
    },
    {
        title: "Lecture 2: Functions and Loops",
        videoUrl: "def456",  // YouTube Video ID
        description: {
            missionTitle: "Functions and Loops",
            missionExplanation: "Learn how to use functions and loops.",
            usedFunctions: ["function", "for-loop", "return"],
            missionCondition : ["마법사 모션 설정하기","배경 설정하기","time 이팩트 3개 추가하기"]
        }
    }
];

const notionURLs = {
    en: [
        "https://www.notioniframe.com/notion/2dfkmkq5gy2"
    ],
    ko: [
        "https://www.notioniframe.com/notion/1f9u7vb8iifl",
    ]
};

function CwsLeftSec({ project, setProject, selectedObjectName }) {
    const [activeTab, setActiveTab] = useState('board2');
    const [currentLectureIndex, setCurrentLectureIndex] = useState(0); // Keep track of current lecture

    const { language } = useLanguage();
    const text = texts[language];
    const notionURL = notionURLs[language];
    // Function to render tab content based on activeTab
    const renderContent = () => {
        if (activeTab === 'board') {
            return (
                <div className="Cws-left-sec-tab-content Cws-left-sec-board-content">
                    <CwsCommandEx selectedObjectName={selectedObjectName} />
                </div>
            );
        } else if (activeTab === 'board2') {
            return <div className="Cws-left-sec-tab-content Cws-left-sec-board-content"><CwsNotionView notionURL={notionURL[0]} /></div>;
        }
    };

    return (
        <div className={`Cws-left-sec-con language-${language}`}>
            <div className="Cws-left-sec-head">
                <div
                    className={`Cws-left-sec-tab ${activeTab === 'board' ? 'Cws-left-sec-active' : ''}`}
                    onClick={() => setActiveTab('board')}
                >
                    <FaBookOpen size={20} />
                    <span className="language-dependent-text" style={{ marginLeft: "10px" }}>
                        {text.leftSec.command}
                    </span>
                </div>
                <div
                    className={`Cws-left-sec-tab ${activeTab === 'board2' ? 'Cws-left-sec-active' : ''}`}
                    onClick={() => setActiveTab('board2')}
                >
                    <RiQuillPenLine size={20} />
                    <span className="language-dependent-text" style={{ marginLeft: "10px" }}>
                        {text.leftSec.learning}
                    </span>
                </div>
            </div>
            <div className="Cws-left-sec-body">
                {renderContent()}
            </div>
        </div>
    );
}

export default CwsLeftSec;
