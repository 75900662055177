import React, { useRef } from 'react';
import Modal from 'react-modal';
import './CelebrateModal.css';

// Firework particle class (remains unchanged)
class Particle {
    constructor(x, y, ctx, hue) {
        this.x = x;
        this.y = y;
        this.ctx = ctx;
        this.hue = hue;
        this.speed = Math.random() * 5 + 2;
        this.angle = Math.random() * Math.PI * 2;
        this.gravity = 0.05;
        this.friction = 0.97;
        this.decay = Math.random() * 0.02 + 0.01;
        this.alpha = 1;
        this.size = Math.random() * 3 + 2;
    }

    update() {
        this.speed *= this.friction;
        this.x += Math.cos(this.angle) * this.speed;
        this.y += Math.sin(this.angle) * this.speed + this.gravity;
        this.alpha -= this.decay;
    }

    draw() {
        this.ctx.beginPath();
        this.ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        this.ctx.fillStyle = `hsla(${this.hue}, 100%, 50%, ${this.alpha})`;
        this.ctx.fill();
    }
}

// Firework class (remains unchanged)
class Firework {
    constructor(x, y, ctx) {
        this.x = x;
        this.y = y;
        this.ctx = ctx;
        this.particles = [];
        this.hue = Math.random() * 360;

        for (let i = 0; i < 100; i++) {
            this.particles.push(new Particle(x, y, ctx, this.hue));
        }
    }

    update() {
        this.particles.forEach((particle, index) => {
            particle.update();
            if (particle.alpha <= 0) {
                this.particles.splice(index, 1);
            }
        });
    }

    draw() {
        this.particles.forEach((particle) => {
            particle.draw();
        });
    }
}

// Function to draw fireworks on the canvas
const drawFireworks = (canvas, ctx, fireworks, animationRef) => {
    const addFirework = () => {
        const x = Math.random() * canvas.width;
        const y = Math.random() * canvas.height / 2;  // Fireworks explode in the upper half
        fireworks.push(new Firework(x, y, ctx));
    };

    const animateFireworks = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);  // Clear canvas for each frame

        fireworks.forEach((firework, index) => {
            firework.update();
            firework.draw();
            if (firework.particles.length === 0) {
                fireworks.splice(index, 1);  // Remove firework when all particles are gone
            }
        });

        if (Math.random() < 0.1) {  // Increase the frequency of new fireworks
            addFirework();
        }

        animationRef.current = requestAnimationFrame(animateFireworks);
    };

    addFirework();  // Start with an initial firework
    animationRef.current = requestAnimationFrame(animateFireworks);  // Start the animation loop
};

const CelebrateModal = ({ isOpen, onRequestClose }) => {
    const canvasRef = useRef(null);
    const fireworks = useRef([]);  // Store fireworks in a ref to persist across renders
    const animationRef = useRef(null);  // Store the animation frame reference

    // Called once the modal is fully opened
    const onAfterOpen = () => {
        const canvas = canvasRef.current;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;

            fireworks.current = [];  // Reset fireworks when the modal opens
            drawFireworks(canvas, ctx, fireworks.current, animationRef);  // Start the fireworks animation
        }
    };

    // Handle modal close and clean up
    const handleModalClose = () => {
        const canvas = canvasRef.current;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);  // Clear the canvas
            cancelAnimationFrame(animationRef.current);  // Stop the animation
            canvas.width = 0;  // Optionally reset canvas dimensions to 0
            canvas.height = 0;
        }
        onRequestClose();  // Trigger the modal close event
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleModalClose}
            onAfterOpen={onAfterOpen}  // Use onAfterOpen instead of useEffect
            contentLabel="Celebration"
            className="celebrate-modal"
            overlayClassName="celebrate-modal-overlay"
        >
            <div className="celebrate-modal-content" onClick={handleModalClose}>
                <h1 className="celebrate-modal-message">🎉 Mission Complete! 🎉</h1>
                <canvas ref={canvasRef} className="celebrate-modal-fireworks"></canvas>
            </div>
        </Modal>
    );
};

export default CelebrateModal;
