import React, { useRef, useEffect, useState } from 'react';
import '@tensorflow/tfjs-backend-webgl';
import * as handpose from '@tensorflow-models/handpose';

function HandGestureApp() {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [model, setModel] = useState(null);

  // Ball properties
  const ball = {
    x: 200,   // Initial x position
    y: 200,   // Initial y position
    radius: 15, // Ball size
    dx: 8,    // x-axis velocity (increased for faster movement)
    dy: 8,    // y-axis velocity (increased for faster movement)
    gravity: 0.4, // Simulate a gravity effect
    bounceFactor: 1.5, // Stronger bounce effect when hitting the hand
  };

  // Load the HandPose model
  useEffect(() => {
    const loadModel = async () => {
      const handposeModel = await handpose.load();
      setModel(handposeModel);
      console.log('HandPose model loaded');
    };
    loadModel();
  }, []);

  // Set up the webcam feed
  useEffect(() => {
    const setupWebcam = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          console.log('Webcam stream set');
        }
      } catch (err) {
        console.error('Error accessing webcam:', err);
      }
    };
    setupWebcam();
  }, []);

  // Hand landmarks connection lines (indices define connections between points)
  const fingerJoints = {
    thumb: [0, 1, 2, 3, 4],
    indexFinger: [5, 6, 7, 8],
    middleFinger: [9, 10, 11, 12],
    ringFinger: [13, 14, 15, 16],
    pinky: [17, 18, 19, 20],
    palm: [0, 5, 9, 13, 17],  // Connect the palm points
  };

  // Draw hand skeleton using lines
  const drawHandSkeleton = (landmarks, ctx) => {
    Object.keys(fingerJoints).forEach((finger) => {
      const joints = fingerJoints[finger];
      for (let i = 0; i < joints.length - 1; i++) {
        const firstJoint = landmarks[joints[i]];
        const secondJoint = landmarks[joints[i + 1]];

        ctx.beginPath();
        ctx.moveTo(firstJoint[0], firstJoint[1]);
        ctx.lineTo(secondJoint[0], secondJoint[1]);
        ctx.strokeStyle = 'green';
        ctx.lineWidth = 3;
        ctx.stroke();
      }
    });

    // Optionally, connect the palm's outer points
    ctx.beginPath();
    ctx.moveTo(landmarks[0][0], landmarks[0][1]);
    ctx.lineTo(landmarks[17][0], landmarks[17][1]);
    ctx.stroke();
  };

  // Detect hand gestures and move ball
  useEffect(() => {
    const detectHandsAndMoveBall = async () => {
      if (model && videoRef.current && videoRef.current.readyState === 4) {
        const video = videoRef.current;
        const predictions = await model.estimateHands(video);
        const ctx = canvasRef.current.getContext('2d');
        canvasRef.current.width = video.videoWidth;
        canvasRef.current.height = video.videoHeight;

        // Clear canvas for each frame
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

        // Draw the webcam feed onto the canvas
        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        // Move the ball with gravity effect
        ball.dy += ball.gravity;
        ball.x += ball.dx;
        ball.y += ball.dy;

        // Check for ball collisions with the canvas edges
        if (ball.x + ball.radius > canvasRef.current.width || ball.x - ball.radius < 0) {
          ball.x -= ball.dx * 2
          ball.dx = -ball.dx * 0.8; // Reverse x direction and reduce speed slightly
        }
        if (ball.y + ball.radius > canvasRef.current.height || ball.y - ball.radius < 0) {
            ball.y -= ball.dy * 2
          ball.dy = -ball.dy * 0.8; // Reverse y direction and reduce speed slightly
        }

        // Draw the ball
        ctx.beginPath();
        ctx.arc(ball.x, ball.y, ball.radius, 0, Math.PI * 2);
        ctx.fillStyle = 'blue';
        ctx.fill();
        ctx.closePath();

        // Check if hand is detected
        if (predictions.length > 0) {
          predictions.forEach((prediction) => {
            const landmarks = prediction.landmarks;

            // Draw hand skeleton (lines)
            drawHandSkeleton(landmarks, ctx);

            // Detect collision between the ball and hand skeleton (check lines)
            Object.keys(fingerJoints).forEach((finger) => {
              const joints = fingerJoints[finger];
              for (let i = 0; i < joints.length - 1; i++) {
                const firstJoint = landmarks[joints[i]];
                const secondJoint = landmarks[joints[i + 1]];

                // Check for collision with each line segment
                if (detectLineCircleCollision(firstJoint, secondJoint, ball)) {
                  ball.dx = -ball.dx * ball.bounceFactor;  // Sharper bounce
                  ball.dy = -ball.dy * ball.bounceFactor;  // Sharper bounce
                }
              }
            });
          });
        } else {
          // If the hand disappears, give the ball a strong push
          ball.dx = ball.dx * 1;
          ball.dy = ball.dy * 1;
        }
      }
    };

    const detectLineCircleCollision = (p1, p2, ball) => {
      const distX = p2[0] - p1[0];
      const distY = p2[1] - p1[1];
      const len = Math.sqrt(distX * distX + distY * distY);

      const dot = (((ball.x - p1[0]) * distX) + ((ball.y - p1[1]) * distY)) / Math.pow(len, 2);

      const closestX = p1[0] + dot * distX;
      const closestY = p1[1] + dot * distY;

      const distance = Math.sqrt((closestX - ball.x) ** 2 + (closestY - ball.y) ** 2);

      return distance <= ball.radius;
    };

    const animationFrame = () => {
      detectHandsAndMoveBall();
      requestAnimationFrame(animationFrame);
    };

    // Use requestAnimationFrame for smoother performance
    requestAnimationFrame(animationFrame);

    return () => cancelAnimationFrame(animationFrame);
  }, [model]);

  return (
    <div>
      <h1>Hand Gesture and Bouncing Ball</h1>
      <video
        ref={videoRef}
        style={{ display: 'none' }} // Keep the video hidden but active
        autoPlay
        playsInline
      />
      <canvas ref={canvasRef} style={{ width: '640px', height: '480px', border: '1px solid blue' }} />
    </div>
  );
}

export default HandGestureApp;