import React from 'react';
import "./TypoMainBanner.css";


function TypoMainBanner() {
    return (
        <div className='Typo-banner-con'>
            <img src={'./assets/Animation Inspo.gif'} alt="Animated Inspo" className="centered-gif" />
            <h1>TYPO</h1>
        </div>
    );
}

export default TypoMainBanner;
